import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';

import { fixToTwoLocalPrice } from 'utils/formatHelper';

import theme from './theme.module.scss';

const LeadFilterButton: React.FC<{
  label: string;
  leadsCount: number;
  amount: number;
  onClick: () => void;
}> = ({ label, amount, leadsCount, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={classNames(theme.card, hovered ? theme.hoverCard : '')}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <div className={theme.cardTitleContainer}>
        <Typography
          variant="caption"
          fontWeight={600}
          color={theme => theme.palette.secondary.main}
        >
          {label}
        </Typography>
      </div>
      <Box
        className={theme.cardLeadsContainer}
        sx={{
          bgcolor: theme => theme.palette.secondary.light,
          color: 'white',
        }}
      >
        <Typography variant="inherit" fontWeight={600}>
          {fixToTwoLocalPrice(leadsCount)}
        </Typography>
      </Box>
      <Box className={theme.cardAmountContainer}>
        <Typography
          variant="caption"
          fontWeight={600}
          color={theme => theme.palette.secondary.main}
        >
          ₹{amount} Cr
        </Typography>
      </Box>
    </div>
  );
};

export default LeadFilterButton;
