import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Followup, Lead, LeadStatusEnum } from 'types';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const PastFollowupsSection: React.FC<{ followups: Followup[]; lead?: Lead; title?: string }> = ({
  followups,
  lead,
  title,
}) => {
  const navigate = useNavigate();

  const columns: GridColDef[] & readonly GridColDef<any>[] = [
    {
      field: 'referenceId',
      headerName: 'ID',
    },
    {
      field: 'followupType',
      headerName: 'Type',
      minWidth: 80,
      maxWidth: 80,
      hideSortIcons: true,
    },
    {
      field: 'leadStatus',
      headerName: 'Lead Status',
      minWidth: 170,
      valueFormatter: val => getEnumKeyFromValue(LeadStatusEnum, val),
    },
    {
      field: 'followupDate',
      headerName: 'Event Date',
      minWidth: 120,
      maxWidth: 150,
      valueFormatter: val => dayjs(val).format('DD MMM YYYY'),
    },
    {
      field: 'comments',
      flex: 1,
      headerName: 'Comments',
      minWidth: 150,
    },
  ];
  return (
    <Grid container direction="column" rowGap={1.5}>
      <Section
        title={!!title ? title : 'Past Events'}
        collapsible
        action={
          <AddIcon
            fontSize="medium"
            aria-label="Create Event"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (!!!lead) {
                navigate('/followups/create-followup');
                return;
              }
              const initData = {
                lead: { _id: lead._id, name: lead.name, referenceId: lead.referenceId },
                leadStatus: lead.leadStatus,
              };

              navigate(`/followups/create-followup?data=${JSON.stringify(initData)}`);
            }}
          />
        }
      >
        {!!followups.length ? (
          <DataGridTable
            columns={columns}
            sx={{
              mx: -2.1,
            }}
            rows={followups}
            onRowDoubleClick={r => navigate(`/followups/${r.row._id}`)}
            getRowId={row => row.referenceId}
            hideFooter
            hideFooterPagination
          />
        ) : (
          <Grid item py={4} container justifyContent="center">
            <Typography variant="subtitle2">No Events have been added yet.</Typography>
          </Grid>
        )}
      </Section>
    </Grid>
  );
};

export default PastFollowupsSection;
