import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import theme from './theme.module.scss';

const GoBackButton: FC<{
  title: string;
  actions?: any[];
  goBackAction?: () => void;
  hideSparator?: boolean;
}> = ({ title, actions, goBackAction, hideSparator = false }) => {
  const navigate = useNavigate();

  return (
    <div className={theme.topNav}>
      <div className={theme.left}>
        <Box
          component="div"
          className={theme.goBackButton}
          sx={{
            bgcolor: theme => theme.palette.secondary.main,
          }}
          onClick={() => {
            goBackAction ? goBackAction() : navigate(-1);
          }}
          title="Go back"
        >
          <ArrowBackIcon />
        </Box>
        <div className={classNames(theme.title, hideSparator ? '' : theme.separator)}>{title}</div>
      </div>
      <span className={theme.right}>
        {actions && actions.length ? actions.map(action => action) : null}
      </span>
    </div>
  );
};

export const GoBackAndForthButton: FC<{
  title: string;
  nextAction: () => void;
  goBackAction?: () => void;
}> = ({ title, nextAction, goBackAction }) => {
  const navigate = useNavigate();

  return (
    <div className={theme.topNav}>
      <div className={theme.left}>
        <div
          className={theme.goBackButton}
          onClick={() => (goBackAction ? goBackAction() : navigate(-1))}
          title="Go back"
        >
          <ArrowBackIcon />
        </div>
        <div className={theme.title}>{title}</div>
      </div>
      <span className={theme.left}>
        <div className={theme.goBackButton} onClick={() => nextAction()} title="Go next">
          <ArrowForwardIcon />
        </div>
      </span>
    </div>
  );
};

export default GoBackButton;
