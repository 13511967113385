import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import debounce from 'utils/debounce';

type SearchBarProps = {
  name: string;
  label?: string;
  size?: 'small' | 'medium';
  placeholder?: string;
  onSearchInputChange: (arg: any) => any;
} & React.HTMLAttributes<HTMLDivElement>;

const debouncedOnSearchInputChange = debounce((newInput: string, callback: (arg: any) => any) => {
  callback(newInput);
}, 500);

/**
 * for refetching purposes use "onSearchInputChange".
 * it uses debounce function by itself so no need to apply it again.
 * provides validators as well
 */
const SearchBar: React.FC<SearchBarProps> = ({
  className,
  onSearchInputChange,
  name = 'searchId',
  label = 'Search',
  size = 'small',
  placeholder = 'Search..',
  ...props
}) => {
  const [inputVal, setInputVal] = useState('');

  // trims white spaces and checks if min len is 3
  const searchStringRegex = /^\s*[a-zA-Z0-9-](?:\s*[a-zA-Z0-9-]){2,}\s*$/;
  const onQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newInput = e.target.value;
    setInputVal(newInput);

    if (searchStringRegex.test(newInput) || newInput === '') {
      debouncedOnSearchInputChange(newInput, onSearchInputChange);
    }
  };

  return (
    <div className={className} {...props}>
      <TextField
        sx={{ minWidth: '100%' }}
        size={size}
        label={label}
        name={name}
        placeholder={placeholder}
        value={inputVal}
        onChange={onQueryChange}
      />
    </div>
  );
};

export default SearchBar;
