import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Followup } from 'types';

import FollowupSection from 'components/Followups/Sections/FollowupSection';
import PastFollowupsSection from 'components/Followups/Sections/PastFollowupsSection';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_FOLLOWUP_QUERY = gql`
  query GetFollowupById($id: ID!) {
    getFollowupById(_id: $id) {
      _id
      referenceId
      leadStatus
      followupType
      followupDate
      comments
      lostReason
      followupContact {
        people {
          _id
          name
        }
        type
      }
      lead {
        _id
        name
        referenceId
        leadStatus
      }
      pastFollowups {
        _id
        referenceId
        followupType
        leadStatus
        comments
        followupDate
      }
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type GetFollowupQueryResponse = {
  getFollowupById: Followup;
};
type GetFollowupQueryVariables = {
  id: string;
};

const FollowupDetailsPage = () => {
  const { followupId = '' } = useParams<{ followupId: string }>();

  const { data: followup, loading: loadingFollowup } = useQuery<
    GetFollowupQueryResponse,
    GetFollowupQueryVariables
  >(GET_FOLLOWUP_QUERY, {
    variables: {
      id: followupId,
    },
  });

  const loading = loadingFollowup || !!!followup;
  return (
    <Navbar>
      <GoBackButton title="Event Details" />
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2}>
          <FollowupSection followup={followup.getFollowupById} />
          <PastFollowupsSection
            followups={followup.getFollowupById.pastFollowups}
            lead={followup.getFollowupById.lead}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default FollowupDetailsPage;
