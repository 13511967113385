import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import classNames from 'classnames';
import { useUser } from 'contexts/userContext';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import SideNavForMobile from 'components/SideNavForMobile';

import theme from './theme.module.scss';

interface Tab {
  key: string;
  label: string;
  icon: any;
  route: string;
}

const Sidebar: FC<{ tabs: Tab[] }> = ({ tabs }) => {
  const isMobileScreen = useMediaQuery('(max-width:601px)');
  const [isDrawerMinimized, setIsDrawerMinimized] = useState(false);
  const drawersMinWidth = 60;
  const drawersMaxWidth = 270;
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, user } = useUser();

  if (isMobileScreen) {
    return !isDrawerMinimized ? (
      <div className={theme.navIcon}>
        <IconButton
          aria-label="hide"
          size="small"
          sx={{
            margin: '-3px 15px',
          }}
          onClick={() => {
            setIsDrawerMinimized(!isDrawerMinimized);
          }}
          title={isDrawerMinimized ? 'Expand' : 'Collapse'}
        >
          <MenuIcon fontSize="medium" sx={{ color: isDrawerMinimized ? '#006AB4' : '#000000' }} />
        </IconButton>
      </div>
    ) : (
      <SideNavForMobile setIsDrawerMinimized={setIsDrawerMinimized} tabs={tabs} />
    );
  }
  return (
    <Drawer
      sx={{
        width: isDrawerMinimized ? drawersMinWidth : drawersMaxWidth,
        overflowX: 'hidden',
        transition: 'width .5s',
        zIndex: 100,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isDrawerMinimized ? drawersMinWidth : drawersMaxWidth,
          boxSizing: 'border-box',
          transition: 'width .5s',
          backgroundColor: '#F5F5F5',
          borderRight: 'None',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div className={theme.logoContainer}>
        <IconButton
          aria-label="hide"
          size="small"
          sx={{
            margin: '5px 15px',
          }}
          title={isDrawerMinimized ? 'Expand' : 'Collapse'}
          onClick={() => setIsDrawerMinimized(prev => !prev)}
        >
          <MenuIcon fontSize="medium" sx={{ color: !isDrawerMinimized ? '#006AB4' : '#000000' }} />
        </IconButton>
        <span className={theme.usernameContainer}>
          <b>
            {isAuthenticated ? user?.firstName + ' ' + (user?.lastName ?? '') : 'Not logged in'}
          </b>
        </span>
      </div>
      <div className={theme.tabContainer}>
        {tabs.map(tab => (
          <div
            className={classNames(
              theme.tab,
              location.pathname === tab.route ||
                (location.pathname.includes(tab.route) && tab.route !== '/') ||
                // remove this below condition after we had dashboard
                (tab.route === '/' && location.pathname === '/')
                ? theme.active
                : null
            )}
            key={tab.key}
            onClick={() => navigate(tab.route)}
          >
            <span className={theme.icon}>
              {{
                ...tab.icon,
                props: {
                  color:
                    location.pathname === tab.route ||
                    (location.pathname.includes(tab.route) && tab.route !== '/')
                      ? 'primary'
                      : 'inherit',
                },
              }}
            </span>

            <span className={theme.label}>{tab.label}</span>
          </div>
        ))}
      </div>
      <hr />
    </Drawer>
  );
};

export default Sidebar;
