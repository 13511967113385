import { gql, useLazyQuery, useMutation } from '@apollo/client';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import {
  CREATE_ADDRESS_MUTATION,
  CREATE_BRANCH_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
  CreateBranchMutationResponse,
  CreateBranchMutationVariables,
} from 'graphql/mutation/branch';
import {
  PINCODES_QUERY,
  PincodesQueryResponse,
  PincodesQueryVariables,
} from 'graphql/query/pincode';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BranchTypeEnum, Company, CompanyStatus, CompanyType } from 'types';

import Fieldset from 'components/Fieldset';
import GoBackButton from 'components/GoBackButton';
import { Select } from 'components/Inputs/Select';
import TextField, { ContactTextField } from 'components/Inputs/TextField';
import PincodeInput from 'components/Inputs/TextField/PincodeInput';
import Navbar from 'components/Navbar';

import { removeEmptyFields } from 'utils/common';
import { gmapRegex } from 'utils/regexes';

import { PRIMARY_BRANCH_NAME } from '.';

const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      _id
      referenceId
    }
  }
`;

type CreateCompanyMutationResponse = {
  createCompany: Company;
};
type CreateCompanyMutationVariables = {
  input: {
    name: string;
    type: CompanyType;
    status?: CompanyStatus;
  };
};

const validateGoogleMapUrl = (url: string): boolean => {
  return gmapRegex.test(url);
};

const CreateCompanyPage = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [gmapError, setGmapError] = useState<string | null>(null); // State for Google Map error

  const [createCompany, { loading: creatingCompany }] = useMutation<
    CreateCompanyMutationResponse,
    CreateCompanyMutationVariables
  >(CREATE_COMPANY_MUTATION);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [createBranch, { loading: creatingBranch }] = useMutation<
    CreateBranchMutationResponse,
    CreateBranchMutationVariables
  >(CREATE_BRANCH_MUTATION);

  const [getPincodes, { loading: loadingPincodes }] = useLazyQuery<
    PincodesQueryResponse,
    PincodesQueryVariables
  >(PINCODES_QUERY);

  const navigate = useNavigate();

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleAddressChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      address: {
        ...(prev?.address ?? {}),
        [fieldName]: val,
      },
    }));
  };

  const handleBranchChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      branch: {
        ...(prev.branch ?? {}),
        [fieldName]: val,
      },
    }));
  };

  const handleSubmit = async () => {
    if (gmapError) {
      return;
    }
    const formData = { ...formState };
    const addressData = removeEmptyFields(formData.address);
    const branchData = removeEmptyFields(formData.branch);

    delete formData.address;
    delete formData.branch;

    const companyData = removeEmptyFields(formData);

    let addressId, companyId;

    if ((!!addressData && !!branchData) || !!companyData) {
      const promises: any[] = [];
      promises.push(
        createCompany({
          variables: {
            input: {
              name: companyData.name,
              type: companyData.type,
              status: companyData.status ?? undefined,
            },
          },
          onCompleted: res => (companyId = res.createCompany._id),
        })
      );

      promises.push(
        createAddress({
          variables: {
            input: addressData,
          },
          onCompleted: res => (addressId = res.createAddress._id),
        })
      );

      await Promise.all(promises);

      if (!!companyId && !!addressId) {
        createBranch({
          variables: {
            input: {
              ...branchData,
              address: addressId,
              company: companyId,
              name: PRIMARY_BRANCH_NAME,
              type: BranchTypeEnum.HO,
            },
          },
          onCompleted: _ => navigate(`/companies/${companyId}`),
        });
      }
    }
  };

  return (
    <Navbar>
      <GoBackButton title="Create Company" />
      <Grid container columns={12} mb={5}>
        <Grid item mt={2} md={8} lg={6}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container columnSpacing={2} rowGap={2}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  value={formState.name ?? ''}
                  onChange={e => handleChange('name', e.target.value)}
                  required
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Status"
                  value={formState.status ?? ''}
                  onChange={val => handleChange('status', val)}
                  required
                  options={Object.values(CompanyStatus).map(o => ({ label: o, value: o }))}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Type"
                  value={formState.type ?? ''}
                  onChange={val => handleChange('type', val)}
                  required
                  options={Object.values(CompanyType).map(o => ({ label: o, value: o }))}
                />
              </Grid>
              <Grid item xs={12}>
                <Fieldset label="Head Office Details">
                  <Grid columns={12} container columnSpacing={2} rowGap={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Email"
                        value={formState.branch?.email ?? ''}
                        onChange={e => handleBranchChange('email', e.target.value)}
                        required
                        type="email"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ContactTextField
                        label="Mobile"
                        onChange={e => handleBranchChange('mobile1', e.target.value)}
                        value={formState.branch?.mobile1 ?? ''}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider>
                        <Typography variant="overline" fontWeight={600}>
                          Address Details
                        </Typography>
                      </Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Address Line 1"
                        value={formState.address?.addressLine1 ?? ''}
                        onChange={e => handleAddressChange('addressLine1', e.target.value)}
                        type="text"
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Address Line 2"
                        value={formState.address?.addressLine2 ?? ''}
                        onChange={e => handleAddressChange('addressLine2', e.target.value)}
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Location"
                        value={formState.address?.addressLocation ?? ''}
                        onChange={e => handleAddressChange('addressLocation', e.target.value)}
                        required
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <PincodeInput
                        formState={formState?.address ?? {}}
                        getPincodes={getPincodes}
                        loadingPincodes={loadingPincodes}
                        required
                        handleChange={vals => {
                          setFormState(prev => ({
                            ...prev,
                            address: {
                              ...(prev.address ?? {}),
                              ...vals,
                            },
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        label="City"
                        value={formState.address?.city ?? ''}
                        loading={loadingPincodes}
                        sx={{
                          pointerEvents: 'none',
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        label="State"
                        loading={loadingPincodes}
                        sx={{
                          pointerEvents: 'none',
                        }}
                        value={formState?.address?.state ?? ''}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        label="Country"
                        loading={loadingPincodes}
                        value={formState?.address?.country ?? ''}
                        required
                        sx={{
                          pointerEvents: 'none',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        type="text"
                        minRows={2}
                        label="Google Map Link"
                        value={formState.address?.locationUrl ?? ''}
                        error={!!gmapError}
                        helperText={gmapError}
                        onChange={e => {
                          const val = e.target.value;
                          handleAddressChange('locationUrl', val);
                          if (!validateGoogleMapUrl(val)) {
                            setGmapError('Invalid Google Maps URL');
                          } else {
                            setGmapError(null);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => window.open('https://www.google.com/maps', '_blank')}
                                edge="end"
                                aria-label="open google maps"
                              >
                                <GpsFixedIcon color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  variant="contained"
                  loading={creatingAddress || creatingBranch || creatingCompany}
                  type="submit"
                >
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default CreateCompanyPage;
