import ErrorIcon from '@mui/icons-material/Error';
import { Grid, Typography } from '@mui/material';
import React from 'react';

type NoteProps = {
  title: string;
  variant?: 'info' | 'error' | 'warning' | 'success' | 'primary' | 'secondary';
};

const Note: React.FC<NoteProps> = ({ title, variant = 'info' }) => {
  return (
    <Grid
      columnGap={0.5}
      px={1}
      py={1}
      bgcolor={theme => theme.palette[variant].light}
      display="flex"
      alignItems="center"
      color="white"
      boxShadow={2}
    >
      <ErrorIcon fontSize="small" />
      <Typography variant="caption" fontWeight={700} display="flex" alignItems="center">
        {title}
      </Typography>
    </Grid>
  );
};

export default Note;
