import { LeadDashboardContextProvider } from 'contexts/leadDashboardContext';
import { UserContextProvider } from 'contexts/userContext';
import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import ProtectedRoutes from 'components/ProtectedRoutes';

import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import ResetPassword from './Auth/ResetPassword';
import RouteNotFound from './Auth/RouteNotFound';
import CompaniesPage from './Companies';
import BranchDetailsPage from './Companies/BranchDetails';
import CompanyDetailsPage from './Companies/CompanyDetails';
import CreateCompanyPage from './Companies/CreateCompany';
import LeadDashboard from './Dashboard/LeadDashboard';
import DashboardLeads from './Dashboard/LeadDashboard/DashboardLeads';
// import DocumentMasterPage from './DocumentsMaster';
// import CreateDocumentPage from './DocumentsMaster/CreateDocument';
// import DocumentFoldersPage from './DocumentsMaster/DocumentFolders';
// import DocumentsMasterFilesPage from './DocumentsMaster/DocumentMasterFiles';
// import DocumentSubFoldersPage from './DocumentsMaster/DocumentSubfolders';
// import DocumentsFileDetailsPage from './DocumentsMaster/DocumentsFileDetails';
// import Dashboard from './Dashboard';
import FollowupsPage from './Followups';
import CreateFollowup from './Followups/CreateFollowup';
import FollowupDetailsPage from './Followups/FollowupDetails';
import LeadsPage from './Leads';
import CreateLead from './Leads/CreateLead';
import LeadAssociateDetails from './Leads/LeadAssociates';
import LeadDetailsPage from './Leads/LeadDetails';

// import PQsPage from './PQs';
// import PQCreatePage from './PQs/CreatePQ';
// import PQDetails from './PQs/PQDetails';
// import ProjectTemplate from './Projects';
// import CreateProject from './Projects/CreateProject';
// import RFPPage from './RFPs';
// import CreateRFPPage from './RFPs/CreateRFP';
// import RFPDetailsPage from './RFPs/RFPDetails';

export default function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <UserContextProvider>
          <LeadDashboardContextProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<LeadDashboard />} />
                <Route path="/dashboard-leads" element={<DashboardLeads />} />

                <Route path="/companies" element={<CompaniesPage />} />
                <Route path="/companies/create-company" element={<CreateCompanyPage />} />
                <Route path="/companies/:companyId" element={<CompanyDetailsPage />} />
                <Route
                  path="/companies/:companyId/branches/:branchId"
                  element={<BranchDetailsPage />}
                />
                <Route path="/leads" element={<LeadsPage />} />
                <Route path="/leads/create-lead" element={<CreateLead />} />
                <Route path="/leads/:leadId" element={<LeadDetailsPage />} />
                <Route path="/leads/:leadId/:companyType" element={<LeadAssociateDetails />} />
                <Route
                  path="/leads/:leadId/:companyType/:companyId"
                  element={<LeadAssociateDetails />}
                />

                {/* <Route path="/pqs" element={<PQsPage />} />
                <Route path="/pqs/create-pq" element={<PQCreatePage />} />
                <Route path="/pqs/:pqId" element={<PQDetails />} />

                <Route path="/rfps" element={<RFPPage />} />
                <Route path="/rfps/create-rfp" element={<CreateRFPPage />} />
                <Route path="/rfps/:rfpId" element={<RFPDetailsPage />} /> */}

                <Route path="followups" element={<FollowupsPage />} />
                <Route path="followups/create-followup" element={<CreateFollowup />} />
                <Route path="followups/:followupId" element={<FollowupDetailsPage />} />

                {/* <Route path="/documents" element={<DocumentMasterPage />} />
                <Route path="/documents/create-document" element={<CreateDocumentPage />} />
                <Route
                  path="/documents/:folderType/:documentId"
                  element={<DocumentFoldersPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId"
                  element={<DocumentSubFoldersPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId/:subFolderId"
                  element={<DocumentsMasterFilesPage />}
                />
                <Route
                  path="/documents/:folderType/:documentId/:folderId/:subFolderId/:fileId"
                  element={<DocumentsFileDetailsPage />}
                /> */}

                {/* <Route path="/projects" element={<ProjectTemplate />} />
                <Route path="/projects/create-project" element={<CreateProject />} /> */}

                <Route path="/settings" element={<ResetPassword />} />
              </Route>
              <Route path="*" element={<RouteNotFound />} />
            </Routes>
          </LeadDashboardContextProvider>
        </UserContextProvider>
      </Suspense>
    </Router>
  );
}
