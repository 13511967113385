import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Company } from 'types';

import Branches from 'components/Branches';
import CompanySection from 'components/Companies/CompanySection';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const COMPANY_QUERY = gql`
  query GetCompanyById($id: ID!) {
    getCompanyById(_id: $id) {
      _id
      name
      referenceId
      status
      type
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type CompanyQueryResponse = {
  getCompanyById: Company;
};

type CompanyQueryVariables = {
  id: string;
};

const CompanyDetailsPage = () => {
  const { companyId = '' } = useParams<{ companyId: string }>();
  const navigate = useNavigate();

  const { data: company, loading: loadingCompany } = useQuery<
    CompanyQueryResponse,
    CompanyQueryVariables
  >(COMPANY_QUERY, {
    variables: {
      id: companyId,
    },
  });

  const loading = loadingCompany || !!!company;

  return (
    <Navbar>
      <GoBackButton
        title={loading ? 'Company Details' : company.getCompanyById.name}
        goBackAction={() => navigate('/companies')}
      />
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={2} rowGap={2.5}>
          <CompanySection company={company.getCompanyById} />
          <Branches company={company.getCompanyById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default CompanyDetailsPage;
