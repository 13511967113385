import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
// import DescriptionIcon from '@mui/icons-material/Description';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import GroupsIcon from '@mui/icons-material/Groups';
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import TuneIcon from '@mui/icons-material/Tune';
import { Box } from '@mui/material';
import { FC } from 'react';

import Sidebar from 'components/Sidebar';

const tabs = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    icon: <SpaceDashboardIcon fontSize="small" />,
    route: '/',
  },
  {
    key: 'companies',
    label: 'Companies',
    icon: <BusinessIcon fontSize="small" />,
    route: '/companies',
  },
  {
    key: 'leads',
    label: 'Leads',
    icon: <AccountTreeIcon fontSize="small" />,
    route: '/leads',
  },
  {
    key: 'followups',
    label: 'Events',
    icon: <GroupsIcon fontSize="small" />,
    route: '/followups',
  },
  // {
  //   key: 'documents',
  //   label: 'Document Master',
  //   icon: <FileCopyIcon fontSize="small" />,
  //   route: '/documents',
  // },
  // {
  //   key: 'pqs',
  //   label: 'PQs',
  //   icon: <DescriptionIcon fontSize="small" />,
  //   route: '/pqs',
  // },
  // {
  //   key: 'rfps',
  //   label: 'RFP',
  //   icon: <RequestQuoteIcon fontSize="small" />,
  //   route: '/rfps',
  // },
  // {
  //   key: 'projects',
  //   label: 'Projects',
  //   icon: <FolderCopyIcon fontSize="small" />,
  //   route: '/projects',
  // },
  {
    key: 'settings',
    label: 'Settings',
    icon: <TuneIcon fontSize="small" />,
    route: '/settings',
  },
];

const ResponsiveSidebarLayout: FC<{ children: any }> = props => {
  const renderContent = () => {
    return (
      <>
        <Sidebar tabs={tabs} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: 2,
            pt: 1,
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <div>{props.children}</div>
        </Box>
      </>
    );
  };

  return <Box sx={{ display: 'flex' }}>{renderContent()}</Box>;
};

export default ResponsiveSidebarLayout;
