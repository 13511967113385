import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadSegmentEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import SearchBar from 'components/Inputs/Search';
import LeadsTemplate from 'components/Leads';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      scope
      segment
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      leadStatus
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

type GetLeadsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
  };
};

const LeadsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const {
    data: leads,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetLeadsQueryResponse, GetLeadsQueryVariables>(GET_LEADS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const leadsActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-lead')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!leads;

  return (
    <Navbar title="Leads" actions={leadsActions}>
      <SearchBar
        name="company search"
        label="Search a Lead"
        placeholder="ID / Name"
        onSearchInputChange={searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          })
        }
        style={{ marginBlock: 15, paddingTop: 5 }}
      />
      {loadingLeads ? <LoadingIndicator size="1.6rem" /> : <LeadsTemplate leads={leads.getLeads} />}
    </Navbar>
  );
};

export default LeadsPage;
