import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Typography } from '@mui/material';
import { useUser } from 'contexts/userContext';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import theme from './theme.module.scss';

const SideNavForMobile = ({ setIsDrawerMinimized, tabs }) => {
  const [openDrawer, toggleDrawer] = useState(true);

  const location = useLocation();

  const { user, isAuthenticated } = useUser();

  return (
    <div className={theme.container}>
      <MenuIcon
        onClick={() => {
          toggleDrawer(!openDrawer);
        }}
        className={theme.column}
      />

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {
          toggleDrawer(!openDrawer);
          setIsDrawerMinimized(!openDrawer);
        }}
      >
        <div className={theme.drawerList}>
          <div className={theme.logoContainer}>
            <div className={theme.name}>
              <Typography variant="subtitle1" fontWeight={500}>
                {isAuthenticated ? user?.firstName + ' ' + user?.lastName : 'Not logged in'}
              </Typography>
            </div>
          </div>
          {tabs.map(tab => {
            const color =
              location.pathname === tab.route ||
              (location.pathname.includes(tab.route) && tab.route !== '/')
                ? 'primary'
                : 'inherit';

            return (
              <NavLink to={tab.route} key={tab.key}>
                <div className={theme.menus}>
                  <span>
                    {{
                      ...tab.icon,
                      props: {
                        color,
                      },
                    }}
                  </span>
                  <span>
                    <Typography color={color} variant="body2">
                      {tab.label}
                    </Typography>
                  </span>
                </div>
              </NavLink>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
};

export default SideNavForMobile;
